import React, { useEffect, useContext } from 'react';
import { Link, graphql, StaticQuery } from 'gatsby';
import styled, { css } from 'styled-components';
import ReactMarkdown from 'react-markdown';

import Layout from 'components/layout';
import { device } from 'components/device';
import SEO from 'components/seo';
import { FeaturedMarketing } from 'components/CmsComponents/FeaturedMarketing';

import { HeaderContext } from 'context/header';

const Container = styled.div`
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 73.125rem;
  margin: 0 auto;
  padding: 0;

  ${({ borderItemsByLength }) =>
    borderItemsByLength > 2 &&
    css`
      ${borderItemsByLength % 2 === 0 &&
      css`
        & > div:nth-child(${borderItemsByLength - 1}) {
          border-bottom: none;

          @media ${device.laptop} {
            border-bottom: 1px solid #c6c8cc;
          }
        }
      `}

      & > div:nth-child(${borderItemsByLength}) {
        border-bottom: none;
      }
    `}

  @media ${device.laptop} {
    flex-direction: column;
    padding: 0 1.25rem;
  }
`;

const WrapGuideContent = styled.div`
  width: 100%;
  max-width: 35.563rem;
  padding-bottom: 4.375rem;
  padding-top: 4.375rem;
  border-bottom: 1px solid #c6c8cc;

  &:nth-child(odd) {
    padding-right: 1.875rem;

    @media ${device.laptop} {
      padding-right: 0;
    }
  }

  @media ${device.laptop} {
    max-width: unset;
    padding-bottom: 1.25rem;
    padding-top: 1.25rem;
  }
`;

const GuideImage = styled.img`
  width: 100%;
  height: 100%;
  min-height: 20rem;
  max-height: 20rem;

  object-fit: cover;
  object-position: center;

  margin-bottom: 1.875rem;

  @media ${device.laptop} {
    margin-bottom: 1.25rem;
    object-position: top;
  }

  @media ${device.mobile} {
    min-height: 11.625rem;
    max-height: 11.625rem;
    object-position: center;
  }
`;

const GuideContent = styled(ReactMarkdown)`
  h2 {
    font-size: 1.5rem;
    line-height: 1.875rem;
    font-weight: 600;
    color: #231f20;
    margin-bottom: 0;

    @media ${device.laptop} {
      font-size: 1.125rem;
    }
  }

  h3 {
    font-size: 1.5rem;
    line-height: 1.875rem;
    font-weight: normal;
    color: #231f20;
    margin-bottom: 0;

    @media ${device.laptop} {
      font-size: 1.125rem;
    }
  }

  p {
    font-size: 1rem;
    line-height: 1.625rem;
    color: #231f20;

    &:first-of-type {
      margin-top: 1.875rem;
    }

    @media ${device.laptop} {
      font-size: 0.875rem;

      &:first-of-type {
        margin-top: 1.25rem;
      }
    }
  }

  a {
    font-size: 1rem;
    line-height: 1.625rem;
    color: #45a7df;
    display: flex;
    align-items: center;

    &[href^='/receitas-'] {
      margin-top: 1.25rem;
    }

    @media ${device.laptop} {
      font-size: 0.875rem;
    }
  }
`;

const RecipesHub = ({ page }) => {
  const {
    setMainMarginTopLaptop,
    setMainMarginTopMobile,
    showAlertPostFeriado,
    isVisibleNovoSite,
  } = useContext(HeaderContext);

  const featuredMarketingContent = page?.featuredMarketing;
  const recipesHubContent = page?.fragments ?? [];
  const recipesHubContentLength = recipesHubContent?.length ?? 0;

  const dataSeo = [
    {
      seo: {
        metaTitle: page?.seo?.metaTitle ?? '',
        metaDescription: page?.seo?.metaDescription ?? '',
      },
    },
  ];

  const LinkRender = ({ href, children }) => {
    const isInternalLink = href.startsWith('/');
    if (isInternalLink) {
      return <Link to={href}>{children}</Link>;
    }
    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  };

  useEffect(() => {
    if (showAlertPostFeriado || isVisibleNovoSite) {
      setMainMarginTopLaptop('9.075rem');
      setMainMarginTopMobile('7.875rem');
    } else {
      setMainMarginTopLaptop('7.063rem');
      setMainMarginTopMobile('5.875rem');
    }
  }, [showAlertPostFeriado, isVisibleNovoSite]);

  return (
    <Layout>
      <SEO
        dataSeo={dataSeo}
        image={page?.featuredMarketing?.images?.[0]?.url ?? ''}
      />
      {featuredMarketingContent && (
        <FeaturedMarketing content={featuredMarketingContent} />
      )}
      <Container>
        <Content borderItemsByLength={recipesHubContentLength}>
          {recipesHubContent.map(hubContent => (
            <WrapGuideContent key={hubContent.id}>
              <Link to={hubContent.datafragment?.[0].link ?? '/'}>
                {hubContent.assetpicker?.url && (
                  <GuideImage
                    src={hubContent.assetpicker?.url}
                    alt="Guia de Receitas"
                  />
                )}

                <GuideContent
                  renderers={{
                    link: LinkRender,
                  }}
                >
                  {hubContent.markdown}
                </GuideContent>
              </Link>
            </WrapGuideContent>
          ))}
        </Content>
      </Container>
    </Layout>
  );
};

export default props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "ckzfyepw066yc0d82dacnbtn3" }) {
                fragments(locales: [pt_BR, en]) {
                  id
                  name
                  singletexts
                  markdown
                  datafragment
                  assetpicker {
                    handle
                    width
                    height
                    url
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
                featuredMarketing(locales: [pt_BR, en]) {
                  id
                  type
                  breadcrumb
                  backgroundColor {
                    hex
                  }
                  images {
                    handle
                    width
                    height
                    url
                  }
                  title
                  fragments {
                    id
                    name
                    type
                    markdown
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return (
          <RecipesHub page={response.gcms.site.pages?.[0] ?? []} {...props} />
        );
      }}
    />
  );
};
